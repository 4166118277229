import { useCookies } from '@vueuse/integrations/useCookies'
import { CookieSetOptions } from 'universal-cookie'

export function useClassFilter() {
	const cookies = useCookies(['class_filter'])
	const classOptions = useProperty<App.Data.ClassData[]>('classOptions')
	const cookieSetOptions = <CookieSetOptions>{
		path: '/',
		expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
	}

	if (!cookies.get('class_filter')) {
		cookies.set('class_filter', [], cookieSetOptions)
	}

	const selectedClasses = computed<App.Data.ClassData[]>(() => cookies.get('class_filter'))

	const toggleClassOption = (classCode: string) => {
		if (selectedClasses.value?.some((option) => option.class_code === classCode)) {
			cookies.set(
				'class_filter',
				selectedClasses.value.filter((option) => option.class_code !== classCode),
				cookieSetOptions,
			)
		} else {
			cookies.set(
				'class_filter',
				[...selectedClasses.value, classOptions.value.find((option) => option.class_code === classCode)],
				cookieSetOptions,
			)
		}
	}

	const isClassSelected = (classCode: string) => {
		if (!selectedClasses.value) {
			return true
		}

		return selectedClasses.value?.some((option) => option.class_code === classCode)
			|| selectedClasses.value.length === 0
			|| (classOptions && !classOptions.value?.some((option) => option.class_code === classCode))
	}

	const selectAllClasses = () => {
		cookies.set('class_filter', [], cookieSetOptions)
	}

	return {
		selectedClasses,
		selectedClassesCount: computed(() => selectedClasses.value?.length ?? 0),
		classOptions,
		toggleClassOption,
		isClassSelected,
		selectAllClasses,
	}
}
