<script setup lang="ts">
const $props = defineProps<{
	organizer: App.Data.OrganizerData
	events: App.Data.EventData[]
	pastEvents: Paginator<App.Data.EventData>
}>()

defineLayoutProperties({
	pageTitle: `${$props.organizer.name} - ${t('page.organizer_index.meta_title')}`,
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<div class="space-y-4">
		<template v-if="$props.events.length">
			<div class="bg-primary px-4 py-2">
				<heading-2 class="text-white">
					{{ t('app.events') }}
				</heading-2>
			</div>

			<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				<event-card v-for="event in $props.events" :key="event.uuid" :event="event" />
			</div>
		</template>

		<template v-if="$props.pastEvents.data.length">
			<heading-bar>
				<heading-2 class="text-white">
					{{ t('app.past_events') }}
				</heading-2>
			</heading-bar>

			<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				<event-card v-for="event in $props.pastEvents.data" :key="event.uuid" :event="event" />
			</div>

			<pagination :links="$props.pastEvents.links" />
		</template>
	</div>
</template>
