<script setup lang="ts">
import { breakpointsTailwind, UseResizeObserverReturn } from '@vueuse/core'
import isEmpty from 'lodash/isEmpty'

const $props = defineProps<{
	event: App.Data.EventData
	activeRoundSlug: string
	activeRoundName: string
	roundFile: App.Data.FileData | null
	heats: App.Data.HeatData[]
}>()

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('organizer.show', { organizer: $props.event.organizer?.uuid }),
	subTitle: $props.activeRoundName,
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const { selected, filteredSearchItems, searchQuery, following, followingTotal, isRiderFollowed, removeFollow } = useFollowing()
const { selectedClassesCount, classOptions, toggleClassOption, isClassSelected, selectAllClasses } = useClassFilter()
const [showClassFilters, toggleClassFilters] = useToggle()
const [showFollowing, toggleFollowing] = useToggle()
const [showRounds, toggleRounds] = useToggle()

if (breakpoints.greaterOrEqual('lg').value) {
	toggleFollowing(true)
}

const activeHeatCount = computed(() => {
	return $props.heats.filter((heat) => isClassSelected(heat.class_code)).length
})

const shortenRiderName = (name: string) => {
	return name.length > 18 ? `${name.substring(0, 18)}...` : name
}

const shortenTeamName = (name: string | null) => {
	if (!name) {
		return null
	}

	return name.length > 18 ? `${name.substring(0, 18)}...` : name
}

const cacheBust = Date.now()

const tableWrapper = ref<HTMLDivElement | null>(null)
const nameColumnWidth = ref<string>('auto')
let resizeObserver: UseResizeObserverReturn | null = null

onMounted(() => {
	resizeObserver = useResizeObserver(tableWrapper, (entries) => {
		if (!entries.length) {
			return
		}

		if (breakpoints.smaller('sm').value) {
			nameColumnWidth.value = 'auto'

			return
		}

		if (breakpoints.greaterOrEqual('xl').value) {
			nameColumnWidth.value = '360px'

			return
		}

		const tableWrapperWidth = entries[0].contentRect.width

		if (breakpoints.smaller('md').value) {
			nameColumnWidth.value = `${tableWrapperWidth - 359}px`

			return
		}

		nameColumnWidth.value = `${tableWrapperWidth - 414}px`
	})
})

onUnmounted(() => {
	resizeObserver?.stop()
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<div class="-mx-5 -my-6 mb-4 hidden sm:-mx-6 lg:block">
		<div class="flex flex-col divide-y rounded-t-lg md:flex-row md:divide-x md:divide-y-0 md:divide-gray-200">
			<router-link
				v-for="round in $props.event.rounds"
				:key="round['slug'] ?? ''"
				:href="route('event.show', { event: $props.event.uuid, roundSlug: (round['slug'] ?? '') })"
				:class="{ '!border-b-primary text-primary': (round['slug'] ?? '') === $props.activeRoundSlug }"
				class="flex grow items-center !border-b-[3px] p-4 text-sm font-semibold hover:text-primary"
			>
				{{ round['name'] ?? '' }}
				<span v-if="round['is_live'] ?? false" class="ml-2 rounded bg-primary px-1 py-0.5 text-xs text-red-50">{{ round['is_live'] }}</span>
			</router-link>
		</div>
	</div>

	<div class="lg:hidden">
		<div class="flex">
			<heading-bar class="grow cursor-pointer !bg-gray-600 hover:!bg-gray-800" @click="toggleRounds()">
				<heading-3 class="flex items-center justify-between text-white">
					<span class="flex items-center">
						<i-gg-alarm class="mr-2 h-5 w-5" />
						{{ activeRoundName }}
					</span>
					<i-gg-chevron-down :class="{ 'rotate-180': showRounds }" class="h-5 w-5" />
				</heading-3>
			</heading-bar>
			<div class="ml-4">
				<a v-if="roundFile" :href="roundFile.url + '?v=' + cacheBust" target="_blank" class="flex h-full items-center bg-gray-50 p-2 text-sm font-semibold hover:bg-gray-200">
					<file-icon :link="roundFile" class="h-6 w-10 text-primary" />
				</a>
				<span v-else class="flex h-full cursor-not-allowed items-center bg-gray-50 p-2 text-sm font-semibold opacity-50">
					<i-codicon-file-pdf class="h-6 w-6 text-gray-500" />
				</span>
			</div>
		</div>

		<div v-if="showRounds" class="mt-2 grid gap-2">
			<router-link
				v-for="round in $props.event.rounds"
				:key="round['slug'] ?? ''"
				:href="route('event.show', { event: $props.event.uuid, roundSlug: (round['slug'] ?? '') })"
				class="flex items-center bg-gray-50 p-2 text-sm font-semibold"
			>
				{{ round['name'] ?? '' }}
				<span v-if="round['is_live'] ?? false" class="ml-2 rounded bg-primary px-1 py-0.5 text-xs text-red-50">{{ round['is_live'] }}</span>
			</router-link>
		</div>
	</div>

	<div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
		<div ref="tableWrapper" class="order-2 col-span-2 space-y-4 lg:order-1">
			<template v-for="heat in $props.heats" :key="heat.id">
				<div v-if="isClassSelected(heat.class_code)">
					<heading-bar>
						<heading-3 class="flex items-center text-white">
							<i-gg-alarm class="mr-2 h-5 w-5" />
							{{ heat.name }}
							<i-mdi-phone-rotate-landscape v-if="!isEmpty(heat.additional_columns)" class="ml-auto h-5 w-5 text-white sm:hidden" />
						</heading-3>
					</heading-bar>
					<base-table class="whitespace-nowrap">
						<thead class="bg-gray-50">
							<tr>
								<table-th class="min-w-[20px] text-right">
									{{ heat.column_name_rank }}
								</table-th>
								<table-th class="min-w-[30px] text-right">
									{{ heat.column_name_plate }}
								</table-th>
								<table-th class="table-name-column sticky left-0 w-auto bg-gray-50 xl:min-w-[278px]">
									{{ heat.column_name_name }}
								</table-th>
								<table-th v-if="heat.column_name_team" class="hidden min-w-[165px] sm:table-cell md:min-w-[200px]">
									{{ heat.column_name_team }}
								</table-th>
								<table-th class="min-w-[70px] text-right md:min-w-[85px]">
									{{ heat.column_name_result }}
								</table-th>
								<table-th class="min-w-[41px] text-right" />
								<table-th v-for="additionalColumn in heat.additional_columns" :key="activeRoundName + '-' + heat.id + '-' + additionalColumn" class="hidden text-right sm:table-cell">
									{{ additionalColumn }}
								</table-th>
							</tr>
						</thead>
						<tbody class="divide-y divide-gray-200 bg-white">
							<tr v-for="rider in heat.riders" :key="activeRoundName + '-' + heat.id + '-' + rider.rank" :class="{ 'bg-red-100': isRiderFollowed(rider) }">
								<template v-if="rider.id">
									<td>
										<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											{{ rider.rank }}
										</table-link>
									</td>
									<td>
										<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											{{ rider.plate }}
										</table-link>
									</td>
									<td class="sticky left-0 font-semibold" :class="{ 'bg-red-100': isRiderFollowed(rider), 'bg-white': !isRiderFollowed(rider) }">
										<table-link :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											<div class="flex h-full w-full items-center">
												<img
													v-if="rider.ioc_code"
													:src="`/images/flags/` + rider.ioc_code.toUpperCase() + `.PNG`"
													class="mr-2 inline-block h-4 w-6"
												/>
												<span class="hidden text-black md:inline ">{{ rider.name }}</span>
												<span class="text-black md:hidden">{{ shortenRiderName(rider.name) }}</span>
											</div>
										</table-link>
									</td>
									<td class="hidden sm:table-cell">
										<table-link :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											{{ shortenTeamName(rider.team) }}
										</table-link>
									</td>
									<td>
										<table-link class="text-right tabular-nums" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											{{ rider.result }}
										</table-link>
									</td>
									<td>
										<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											<span v-if="rider.qualified" class="inline-flex h-4 items-center justify-center bg-green-300 px-1 text-xs text-green-600">
												{{ rider.qualified }}
											</span>
										</table-link>
									</td>
									<td v-for="additionalColumn in rider.additional_columns" :key="activeRoundName + '-' + heat.id + '-' + rider.rank + '-' + additionalColumn" class="hidden sm:table-cell">
										<table-link class="text-right tabular-nums" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
											{{ additionalColumn }}
										</table-link>
									</td>
								</template>
							</tr>
						</tbody>
					</base-table>
				</div>
			</template>

			<p v-if="activeHeatCount === 0 && selectedClassesCount > 0" class="text-sm text-gray-500">
				{{ t('app.no_results_check_your_active_filters') }}
			</p>

			<p v-if="activeHeatCount === 0 && selectedClassesCount === 0" class="text-sm text-gray-500">
				{{ t('app.no_results') }}
			</p>
		</div>

		<div class="order-1 mb-4 space-y-2 lg:order-2 lg:mb-0">
			<div class="hidden lg:block">
				<a v-if="roundFile" :href="roundFile.url + '?v=' + cacheBust" target="_blank" class="mb-4 flex items-center bg-gray-50 p-2 text-sm font-semibold hover:bg-gray-200">
					<file-icon :link="roundFile" class="mr-2 h-6 w-10 text-primary" />
					<span>{{ roundFile.name }}</span>
				</a>
				<span v-else class="mb-4 flex cursor-not-allowed items-center bg-gray-50 p-2 text-sm font-semibold opacity-50">
					<i-codicon-file-pdf class="mr-2 h-6 w-6 text-primary" />
					<span>{{ activeRoundName }}</span>
				</span>
			</div>

			<heading-bar class="cursor-pointer !bg-gray-600 hover:!bg-gray-800" @click="toggleClassFilters()">
				<heading-3 class="flex items-center justify-between text-white">
					<span class="flex items-center">
						<i-gg-list-tree class="mr-2 h-5 w-5" />
						{{ $props.event.texts ? ($props.event.texts['classes_header_text'] ?? '') : '' }}
						<span v-if="selectedClassesCount > 0" class="pl-1">({{ selectedClassesCount }})</span>
					</span>
					<i-gg-chevron-down :class="{ 'rotate-180': showClassFilters }" class="h-5 w-5" />
				</heading-3>
			</heading-bar>

			<div v-if="showClassFilters" class="space-y-2 text-sm">
				<div v-for="classOption in classOptions" :key="classOption.class_code" class="flex cursor-pointer items-center bg-gray-50 p-2 text-sm font-semibold hover:bg-gray-200" @click="toggleClassOption(classOption.class_code)">
					<input type="checkbox" class="pointer-events-none mr-2" :checked="isClassSelected(classOption.class_code)" />
					{{ classOption.name }}

					<span v-if="classOption.subtitle" class="ml-auto font-normal text-gray-500">{{ classOption.subtitle }}</span>
				</div>
				<div class="flex cursor-pointer items-center text-primary" @click="selectAllClasses()">
					<i-gg-chevron-double-right class="mr-2 h-5 w-5" />
					<span>{{ event.button_text_select_all ? event.button_text_select_all : t('app.select_all') }}</span>
				</div>
			</div>

			<heading-bar class="cursor-pointer !bg-gray-600 hover:!bg-gray-800" @click="toggleFollowing()">
				<heading-3 class="flex items-center justify-between text-white">
					<span class="flex items-center">
						<i-gg-user-list class="mr-2 h-5 w-5" />
						{{ $props.event.texts ? ($props.event.texts['follow_header_text'] ?? '') : '' }}
						<span v-if="followingTotal > 0" class="pl-1">({{ followingTotal }})</span>
					</span>
					<i-gg-chevron-down :class="{ 'rotate-180': showFollowing }" class="h-5 w-5" />
				</heading-3>
			</heading-bar>

			<template v-if="showFollowing">
				<div class="relative">
					<headless-combobox v-model="selected">
						<div class="relative">
							<headless-combobox-input
								:placeholder="$props.event.texts ? ($props.event.texts['follow_search_placeholder'] ?? '') : ''"
								class="w-full border border-gray-400"
								@change="searchQuery = $event.target.value"
							/>
							<i-gg-search class="pointer-events-none absolute right-2.5 top-2.5 h-6 w-6" />
						</div>
						<headless-combobox-options
							class="absolute mt-1 max-h-[250px] w-full divide-y divide-white overflow-y-scroll bg-gray-50"
						>
							<headless-combobox-option
								v-for="searchItem in filteredSearchItems?.slice(0, 25)"
								:key="searchItem.id"
								v-slot="{ active }"
								:value="searchItem"
							>
								<li class="cursor-pointer p-2 text-sm hover:bg-gray-200" :class="{ 'bg-gray-200': active }">
									<img
										v-if="searchItem.ioc_code"
										:src="`/images/flags/` + searchItem.ioc_code.toUpperCase() + `.PNG`"
										class="mr-2 inline-block h-4 w-6"
									/>
									{{ searchItem.name }}
								</li>
							</headless-combobox-option>
						</headless-combobox-options>
					</headless-combobox>
				</div>

				<div class="mt-4 grid gap-3">
					<template v-for="(followingItems, key) in following" :key="key">
						<div
							v-for="followingItem in followingItems"
							:key="key + '' + followingItem.id"
							class="flex items-center bg-gray-50 p-2 text-sm font-semibold"
						>
							<img
								v-if="followingItem.ioc_code"
								:src="`/images/flags/` + followingItem.ioc_code.toUpperCase() + `.PNG`"
								class="mr-2 inline-block h-4 w-6"
							/>
							{{ followingItem.name }}

							<button class="ml-auto text-black hover:text-primary" @click="removeFollow(followingItem)">
								<i-gg-close class="h-4 w-4" />
							</button>
						</div>
					</template>

					<router-link
						v-if="followingTotal > 0"
						:href="route('event.following', { event: event.uuid })"
						class="flex items-center text-sm text-primary hover:underline"
					>
						<i-gg-chevron-double-right class="mr-2 h-5 w-5" />
						<span>{{ $props.event.texts ? ($props.event.texts['show_following_text'] ?? '') : '' }}</span>
					</router-link>
				</div>
			</template>
		</div>
	</div>
</template>

<style scoped>
.table-name-column {
  min-width: v-bind(nameColumnWidth);
}
</style>
