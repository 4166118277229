<script setup lang="ts">
const $props = defineProps<{
	event: App.Data.EventData
	followingItems: App.Data.FollowingItemData[]
}>()

defineLayoutProperties({
	pageTitle: $props.event.name,
	subTitle: t('app.following'),
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<div class="space-y-4">
		<router-link
			:href="route('event.show', { event: event.uuid })"
			class="flex items-center text-sm text-primary hover:underline"
		>
			<i-gg-chevron-double-left class="mr-2 h-5 w-5" />
			<span>{{ $props.event.texts ? ($props.event.texts['following_back_text'] ?? '') : '' }}</span>
		</router-link>

		<p v-if="! $props.followingItems.length" class="text-center text-gray-500">
			{{ t('app.no_follows') }}
		</p>

		<template v-for="followingItem in $props.followingItems" :key="followingItem.id">
			<div>
				<heading-bar>
					<heading-3 class="flex items-center text-white">
						<img
							v-if="followingItem.ioc_code"
							:src="`/images/flags/` + followingItem.ioc_code.toUpperCase() + `.PNG`"
							class="mr-2 inline-block h-4 w-6"
						/>
						{{ followingItem.name }} ({{ followingItem.plate }})
						<template v-if="followingItem.team">
							- {{ followingItem.team }}
						</template>
					</heading-3>
				</heading-bar>
				<base-table>
					<thead class="bg-gray-50">
						<tr>
							<table-th class="w-[200px]">
								{{ $props.event.texts ? ($props.event.texts['following_column_name1'] ?? '') : '' }}
							</table-th>
							<table-th>
								{{ $props.event.texts ? ($props.event.texts['following_column_name2'] ?? '') : '' }}
							</table-th>
							<table-th class="w-[20px] text-right">
								{{ $props.event.texts ? ($props.event.texts['following_column_name3'] ?? '') : '' }}
							</table-th>
							<table-th class="w-[70px] text-right md:w-[85px]">
								{{ $props.event.texts ? ($props.event.texts['following_column_name4'] ?? '') : '' }}
							</table-th>
							<table-th class="w-4 text-right" />
						</tr>
					</thead>
					<tbody class="divide-y divide-gray-200 bg-white">
						<tr v-for="heat in followingItem.heat_data" :key="followingItem.id + '-' + heat.heat_name + '-' + heat.round_name">
							<template v-if="heat.heat_id">
								<td>
									<table-link :href="route('event.heat', { event: event.uuid, roundSlug: heat.round_slug, heat: heat.heat_id })" class="flex h-full w-full items-center space-x-2 font-semibold">
										<span class="text-black">{{ heat.round_name }}</span>
									</table-link>
								</td>
								<td>
									<table-link :href="route('event.heat', { event: event.uuid, roundSlug: heat.round_slug, heat: heat.heat_id })">
										{{ heat.heat_name }}
									</table-link>
								</td>
								<table-td class="text-right">
									{{ heat.rank }}
								</table-td>
								<table-td class="text-right tabular-nums">
									{{ heat.result }}
								</table-td>
								<table-td class="text-right">
									<span v-if="heat.qualified" class="inline-flex h-4 items-center justify-center bg-green-300 px-1 text-xs text-green-600">
										{{ heat.qualified }}
									</span>
								</table-td>
							</template>
						</tr>
					</tbody>
				</base-table>
			</div>
		</template>
	</div>
</template>
