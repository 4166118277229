<script setup lang="ts">
import { tv } from 'tailwind-variants'

const heading2Variants = tv({
	base: 'text-2xl font-semibold tracking-tight',
})
</script>
<template>
	<h2 :class="heading2Variants()">
		<slot />
	</h2>
</template>
