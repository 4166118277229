<script setup lang="ts">
import { tv } from 'tailwind-variants'

const heading1Variants = tv({
	base: 'text-3xl font-semibold tracking-tight',
})
</script>
<template>
	<h1 :class="heading1Variants()">
		<slot />
	</h1>
</template>
