<script setup lang="ts">
const $props = defineProps<{
	pageTitle?: string
	subTitle?: string
	pageTitleLink?: string
}>()

const pageTitle = computed(() => $props.pageTitle || usePageTitle().pageTitle.value)
const { autoRefresh, toggleAutoRefresh } = useAutoRefresh()

useHead({
	title: pageTitle,
})
</script>

<template>
	<div class="bg-gray-800 pb-32">
		<nav class="bg-gray-800">
			<div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div class="border-b border-gray-700">
					<div class="flex h-16 items-center justify-between px-4 sm:px-0">
						<div class="flex items-center">
							<div class="shrink-0">
								<router-link :href="route('organizer.index')">
									<i-jstiming-logo-white class="h-[25px] w-[100px]" />
								</router-link>
							</div>
						</div>
						<div class="flex items-center">
							<div class="flex cursor-pointer items-center space-x-2 rounded bg-white px-2 py-1" @click="toggleAutoRefresh()">
								<input type="checkbox" class="pointer-events-none" :checked="autoRefresh" />
								<span class="text-xs text-gray-700">{{ t('app.auto_refresh') }}</span>
								<i-mdi-reload class="h-4 w-4 text-gray-700" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>

		<header class="relative py-10">
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<heading-1 v-if="pageTitleLink" class="text-white">
					<router-link :href="pageTitleLink">
						{{ pageTitle }}
					</router-link>
				</heading-1>
				<heading-1 v-else class="text-white">
					{{ pageTitle }}
				</heading-1>

				<!--				<heading-2 v-if="$props.subTitle" class="text-gray-300">-->
				<!--					{{ $props.subTitle }}-->
				<!--				</heading-2>-->
			</div>
		</header>
	</div>

	<main class="-mt-32">
		<div class="mx-auto max-w-7xl pb-12 md:px-6 lg:px-8">
			<div class="min-h-[400px] rounded-lg bg-white px-4 py-6 shadow sm:px-6">
				<slot />
			</div>
		</div>
	</main>
</template>
