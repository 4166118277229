<script setup lang="ts">
const $props = defineProps<{
	event: App.Data.EventData
	files: App.Data.FileData[]
	links: App.Data.LinkData[]
}>()

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('organizer.show', { organizer: $props.event.organizer?.uuid }),
	subTitle: t('app.files'),
})

const cacheBust = Date.now()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<div class="grid grid-cols-1 gap-4 md:grid-cols-3">
		<a v-for="file in $props.files" :key="file.url" :href="file.url + '?v=' + cacheBust" target="_blank" class="flex items-center bg-gray-50 p-2 text-sm font-semibold hover:bg-gray-200">
			<file-icon :link="file" class="mr-2 h-6 w-10 text-primary" />
			<span>{{ file.name }}</span>
		</a>

		<a v-for="link in $props.links" :key="link.url" :href="link.url " target="_blank" class="flex items-center bg-gray-50 p-2 text-sm font-semibold hover:bg-gray-200">
			<i-jstiming-link class="mr-2 h-6 w-10 text-primary" />
			<span>{{ link.name }}</span>
		</a>
	</div>
</template>
