<template>
	<div class="flow-root">
		<div class="overflow-x-auto">
			<div class="inline-block min-w-full align-middle">
				<table class="min-w-full divide-y divide-gray-300">
					<slot />
				</table>
			</div>
		</div>
	</div>
</template>
