import { useCookies } from '@vueuse/integrations/useCookies'
import { CookieSetOptions } from 'universal-cookie'

export function useAutoRefresh() {
	const cookies = useCookies(['auto_refresh'])
	const cookieSetOptions = <CookieSetOptions>{
		path: '/',
		expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
	}
	const component = computed(() => useContext().value?.view.component || '')
	const autoRefresh = computed(() => cookies.get('auto_refresh'))
	const refreshComponents = ['event.show', 'event.following', 'event.files']

	// 30 seconds
	const refreshInterval = 1000 * 30

	setInterval(() => {
		if (!refreshComponents.includes(component.value)) {
			return
		}

		if (!autoRefresh.value) {
			return
		}

		router.reload()
	}, refreshInterval)

	if (!cookies.get('auto_refresh')) {
		cookies.set('auto_refresh', false, cookieSetOptions)
	}

	const toggleAutoRefresh = () => {
		cookies.set('auto_refresh', !cookies.get('auto_refresh'), cookieSetOptions)
	}

	return {
		toggleAutoRefresh,
		autoRefresh,
	}
}
