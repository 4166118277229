<script setup lang="ts">
const $props = defineProps<{
	organizers: App.Data.OrganizerData[]
}>()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<router-link v-for="organizer in $props.organizers" :key="organizer.uuid" :href="route('organizer.show', { organizer: organizer.uuid })">
		<div class="mb-4 flex items-center justify-between border border-gray-200 p-4 hover:bg-gray-200">
			<div class="flex items-center space-x-4">
				<div class="w-[60px] shrink-0">
					<img v-if="organizer.logo_url" class="mx-auto max-h-10 bg-white" :src="organizer.logo_url" :alt="organizer.name" />
				</div>
				<strong>{{ organizer.name }}</strong>
			</div>

			<i-gg-chevron-right class="h-6 w-6 text-primary" />
		</div>
	</router-link>

	<p v-if="! $props.organizers.length" class="text-center text-gray-500">
		{{ t('app.no_organizers_found') }}
	</p>
</template>
