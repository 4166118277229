<script setup lang="ts">
const $props = defineProps<{
	status: number
}>()

const title = computed(() => t(`page.error_${$props.status ?? 503}.title`))
const subTitle = computed(() => t(`page.error_${$props.status ?? 503}.sub_title`))

useHead({
	title: () => title.value,
})
</script>

<template>
	<div class="grid min-h-screen place-items-center bg-white px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
		<main class="mx-auto max-w-max">
			<section class="items-center sm:flex">
				<p class="text-primary-300 text-4xl font-bold tracking-tight sm:text-5xl" v-text="status" />
				<div class="sm:ml-6">
					<div class="sm:border-l sm:border-gray-200 sm:pl-6">
						<heading1>{{ title }}</heading1>
						<p class="mt-1 text-gray-500" v-text="subTitle" />
					</div>
				</div>
			</section>

			<div class="mt-6 flex space-x-3 sm:mt-10 sm:border-l sm:border-transparent sm:pl-6 md:ml-28">
				<router-link class="text-primary hover:underline" :href="route('organizer.index')">
					{{ t('app.back_to_home') }}
				</router-link>
			</div>
		</main>
	</div>
</template>
