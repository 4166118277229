<script setup lang="ts">
import { tv } from 'tailwind-variants'

const headingBarVariants = tv({
	base: 'bg-gray-800 px-4 py-2',
})
</script>
<template>
	<div :class="headingBarVariants()">
		<slot />
	</div>
</template>
