<script setup lang="ts">
import { breakpointsTailwind, UseResizeObserverReturn } from '@vueuse/core'
import isEmpty from 'lodash/isEmpty'

const $props = defineProps<{
	event: App.Data.EventData
	heats: App.Data.HeatData[]
	activeRoundSlug: string
	activeRoundName: string
}>()

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('organizer.show', { organizer: $props.event.organizer?.uuid }),
})

const breakpoints = useBreakpoints(breakpointsTailwind)

const shortenRiderName = (name: string) => {
	return name.length > 18 ? `${name.substring(0, 18)}...` : name
}

const shortenTeamName = (name: string | null) => {
	if (!name) {
		return null
	}

	return name.length > 18 ? `${name.substring(0, 18)}...` : name
}

const tableWrapper = ref<HTMLDivElement | null>(null)
const nameColumnWidth = ref<string>('auto')
let resizeObserver: UseResizeObserverReturn | null = null

onMounted(() => {
	resizeObserver = useResizeObserver(tableWrapper, (entries) => {
		if (!entries.length) {
			return
		}

		if (breakpoints.smaller('sm').value) {
			nameColumnWidth.value = 'auto'

			return
		}

		if (breakpoints.greaterOrEqual('xl').value) {
			nameColumnWidth.value = '360px'

			return
		}

		const tableWrapperWidth = entries[0].contentRect.width

		if (breakpoints.smaller('md').value) {
			nameColumnWidth.value = `${tableWrapperWidth - 359}px`

			return
		}

		nameColumnWidth.value = `${tableWrapperWidth - 414}px`
	})
})

onUnmounted(() => {
	resizeObserver?.stop()
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<div ref="tableWrapper" class="order-2 col-span-2 space-y-4 lg:order-1">
		<router-link
			:href="route('event.show', { event: event.uuid })"
			class="flex items-center text-sm text-primary hover:underline"
		>
			<i-gg-chevron-double-left class="mr-2 h-5 w-5" />
			<span>{{ $props.event.texts ? ($props.event.texts['following_back_text'] ?? '') : '' }}</span>
		</router-link>

		<div v-for="heat in $props.heats" :key="heat.id">
			<heading-bar>
				<heading-3 class="flex items-center text-white">
					<i-gg-alarm class="mr-2 h-5 w-5" />
					{{ activeRoundName + ' - ' + heat.name }}
					<i-mdi-phone-rotate-landscape v-if="!isEmpty(heat.additional_columns)" class="ml-auto h-5 w-5 text-white sm:hidden" />
				</heading-3>
			</heading-bar>
			<base-table class="whitespace-nowrap">
				<thead class="bg-gray-50">
					<tr>
						<table-th class="min-w-[20px] text-right">
							{{ heat.column_name_rank }}
						</table-th>
						<table-th class="min-w-[30px] text-right">
							{{ heat.column_name_plate }}
						</table-th>
						<table-th class="table-name-column sticky left-0 w-auto bg-gray-50 xl:min-w-[278px]">
							{{ heat.column_name_name }}
						</table-th>
						<table-th v-if="heat.column_name_team" class="hidden min-w-[165px] sm:table-cell md:min-w-[200px]">
							{{ heat.column_name_team }}
						</table-th>
						<table-th class="min-w-[70px] text-right md:min-w-[85px]">
							{{ heat.column_name_result }}
						</table-th>
						<table-th class="min-w-[41px] text-right" />
						<table-th v-for="additionalColumn in heat.additional_columns" :key="activeRoundName + '-' + heat.id + '-' + additionalColumn" class="hidden text-right sm:table-cell">
							{{ additionalColumn }}
						</table-th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 bg-white">
					<tr v-for="rider in heat.riders" :key="activeRoundName + '-' + heat.id + '-' + rider.rank">
						<template v-if="rider.id">
							<td>
								<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									{{ rider.rank }}
								</table-link>
							</td>
							<td>
								<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									{{ rider.plate }}
								</table-link>
							</td>
							<td class="sticky left-0 font-semibold">
								<table-link :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									<div class="flex h-full w-full items-center">
										<img
											v-if="rider.ioc_code"
											:src="`/images/flags/` + rider.ioc_code.toUpperCase() + `.PNG`"
											class="mr-2 inline-block h-4 w-6"
										/>
										<span class="hidden text-black md:inline ">{{ rider.name }}</span>
										<span class="text-black md:hidden">{{ shortenRiderName(rider.name) }}</span>
									</div>
								</table-link>
							</td>
							<td class="hidden sm:table-cell">
								<table-link :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									{{ shortenTeamName(rider.team) }}
								</table-link>
							</td>
							<td>
								<table-link class="text-right tabular-nums" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									{{ rider.result }}
								</table-link>
							</td>
							<td>
								<table-link class="text-right" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									<span v-if="rider.qualified" class="inline-flex h-4 items-center justify-center bg-green-300 px-1 text-xs text-green-600">
										{{ rider.qualified }}
									</span>
								</table-link>
							</td>
							<td v-for="additionalColumn in rider.additional_columns" :key="activeRoundName + '-' + heat.id + '-' + rider.rank + '-' + additionalColumn" class="hidden sm:table-cell">
								<table-link class="text-right tabular-nums" :href="route('event.rider', { event: event.uuid, rider: rider.id })">
									{{ additionalColumn }}
								</table-link>
							</td>
						</template>
					</tr>
				</tbody>
			</base-table>
		</div>
	</div>
</template>

<style scoped>
.table-name-column {
  min-width: v-bind(nameColumnWidth);
}
</style>
