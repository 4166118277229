import { useCookies } from '@vueuse/integrations/useCookies'
import { CookieSetOptions } from 'universal-cookie'

export function useFollowing() {
	const searchQuery = ref<string>('')
	const selected = ref<App.Data.SearchItemData>()
	const searchItems = useProperty<App.Data.SearchItemData[]>('searchItems')
	const cookieSetOptions = <CookieSetOptions>{
		path: '/',
		expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
	}

	const cookies = useCookies(['following'])

	if (
		!cookies.get('following')
		|| !cookies.get('following').rider
		|| !cookies.get('following').nation
		|| !cookies.get('following').team
	) {
		cookies.set('following', {
			rider: [],
			nation: [],
			team: [],
		}, cookieSetOptions)
	}

	const following = computed<{
		rider: App.Data.SearchItemData[]
		nation: App.Data.SearchItemData[]
		team: App.Data.SearchItemData[]
	}>(() => cookies.get('following'))

	const followingTotal = computed(() => {
		return following.value?.rider?.length + following.value?.nation?.length + following.value?.team?.length
	})

	const filteredSearchItems = computed(() =>
		searchQuery.value === ''
			? []
			: searchItems.value?.filter((searchItem: App.Data.SearchItemData) =>
				searchItem.name
					.toLowerCase()
					.includes(searchQuery.value.toLowerCase())
				&& !following.value.rider.some(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id === searchItem.id,
				) && !following.value.nation.some(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id === searchItem.id,
				) && !following.value.team.some(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id === searchItem.id,
				),
			),
	)

	watch(selected, (searchItem) => {
		if (searchItem) {
			if (searchItem.type === 'rider') {
				cookies.set('following', {
					...following.value,
					rider: [...following.value.rider, searchItem],
				}, cookieSetOptions)
			}

			if (searchItem.type === 'nation') {
				cookies.set('following', {
					...following.value,
					nation: [...following.value.nation, searchItem],
				}, cookieSetOptions)
			}

			if (searchItem.type === 'team') {
				cookies.set('following', {
					...following.value,
					team: [...following.value.team, searchItem],
				}, cookieSetOptions)
			}

			selected.value = undefined
		}
	})

	const isRiderFollowed = function(rider: App.Data.RiderData) {
		return following.value.rider.some(
			(followingItem: App.Data.SearchItemData) =>
				followingItem.id === rider.id,
		) || following.value.nation.some(
			(followingItem: App.Data.SearchItemData) =>
				followingItem.id === rider.ioc_code,
		) || following.value.team.some(
			(followingItem: App.Data.SearchItemData) =>
				followingItem.id === rider.team,
		)
	}

	const removeFollow = function(searchItem: App.Data.SearchItemData) {
		if (searchItem.type === 'rider') {
			cookies.set('following', {
				...following.value,
				rider: following.value.rider.filter(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id !== searchItem.id,
				),
			}, cookieSetOptions)
		}

		if (searchItem.type === 'nation') {
			cookies.set('following', {
				...following.value,
				nation: following.value.nation.filter(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id !== searchItem.id,
				),
			}, cookieSetOptions)
		}

		if (searchItem.type === 'team') {
			cookies.set('following', {
				...following.value,
				team: following.value.team.filter(
					(followingItem: App.Data.SearchItemData) =>
						followingItem.id !== searchItem.id,
				),
			}, cookieSetOptions)
		}
	}

	return {
		selected,
		filteredSearchItems,
		searchQuery,
		following,
		followingTotal,
		isRiderFollowed,
		removeFollow,
	}
}
