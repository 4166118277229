import { initializeHybridly } from 'virtual:hybridly/config'
import { createHead } from '@vueuse/head'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import i18n from './i18n'
import './tailwind.css'
import './fonts'
import sentryVuePlugin from '@/plugins/vue-sentry-plugin'

initializeHybridly({
	enhanceVue: (vue) => {
		vue
			.use(sentryVuePlugin)
			.use(i18n)
			.use(createHead({
				titleTemplate: (title) => title ? `${title} — Results - JSTiming` : 'Results - JSTiming',
			}))
			.use(autoAnimatePlugin)
	},
	progress: {
		color: '#ff3d3d',
	},
})
